//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        cfaTitle: {
            type: String,
            default: 'View All'
        },
        cfaUrl: {
            type: String
        }
    }
}
