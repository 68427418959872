//
//
//
//

import { isEmpty, replace } from 'lodash'

export default {
    props: {
        source: {
            type: String,
            default: ''
        }
    },
    computed: {
        text: function () {
            if (isEmpty(this.source)) {
                return ''
            }

            return replace(this.source, new RegExp('#|_', 'g'), '')
        }
    }
}
