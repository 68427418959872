//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'lodash'

export default {
    props: {
        section: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            activeIndex: 0
        }
    },
    computed: {
        items: function () {
            return get(this.section, 'fields.items', [])
        },
        swiper () {
            return this.$refs.swiper.$swiper
        },
        activeContent () {
            return this.items[this.activeIndex]
        },
        swiperOptions () {
            return {
                navigation: {
                    prevEl: `#swiper-prev-${this._uid}`,
                    nextEl: `#swiper-next-${this._uid}`
                },
                centeredSlides: true,
                slidesPerView: 1.5,
                loop: true,
                breakpoints: {
                    1024: {
                        slidesPerView: 4
                    }
                }
            }
        }
    },
    methods: {
        get,
        swiperSlidePrev () {
            this.swiper.slidePrev()
        },
        swiperSlideNext () {
            this.swiper.slideNext()
        },
        onSlideChange () {
            this.activeIndex = this.swiper.realIndex
        }
    }
}
