//
//
//
//

import { assign, get } from 'lodash'

export default {
    props: {
        media: {
            type: Object,
            required: true
        },
        options: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {
            defaultOptions: { fm: this.$userAgent.browserName === 'Edge' ? 'webp' : 'avif' }
        }
    },
    computed: {
        src: function () {
            const url = get(this.media, 'fields.file.url')
            const query = new URLSearchParams(assign({}, this.defaultOptions, this.options)).toString()

            return `${url}?${query}`
        },
        width: function () {
            if ('w' in this.options && 'h' in this.options) {
                return get(this.options, 'w')
            } else {
                return get(this.media, 'fields.file.details.image.width')
            }
        },
        height: function () {
            if ('w' in this.options && 'h' in this.options) {
                return get(this.options, 'h')
            } else {
                return get(this.media, 'fields.file.details.image.height')
            }
        }
    },
    methods: { get }
}
